import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  doc,
  DocumentData,
  documentId,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  where,
} from '@angular/fire/firestore';
import { Business } from 'projects/support-agent/src/app/shared/models/business.model';
import { DispatchTag } from 'projects/support-agent/src/app/shared/models/dispatch-tag.model';
import {
  DriverAggregate,
  DriverEta,
  DriverState,
  DriverTelemetry,
} from 'projects/support-agent/src/app/shared/models/driver.model';
import { PartnerCompanyAggregate } from 'projects/support-agent/src/app/shared/models/partner.model';
import { ProductFeature } from 'projects/support-agent/src/app/shared/models/product-feature.model';
import { RiderAggregate } from 'projects/support-agent/src/app/shared/models/rider.module';
import { VehicleAggregate, VehicleType } from 'projects/support-agent/src/app/shared/models/vehicle.model';
import { catchError, from, map, mergeMap, Observable, throwError, toArray } from 'rxjs';
import { PartnerCompany } from '../../finance-manager/models/partner-company.model';
import { EconSettlementFile, JBankSettlementFile } from '../../finance-manager/models/partner.model';
import { AccountUser } from '../models/account-user.model';
import { AppConfig } from '../models/app-config.model';
import { Area } from '../models/area.model';
import { BookingAggregate } from '../models/booking.model';
import { MetaOp } from '../models/entity.model';
import { UserRoles } from '../models/enums.model';
import { ExternalFee } from '../models/external-fees.model';
import { FirebaseUser } from '../models/firebase-user.model';
import { CreditNote, PartnerSettlement } from '../models/partner-company.model';
import { Quote } from '../models/quote.model';
@Injectable({ providedIn: 'any' })
export class ConsoleFirestoreService {
  constructor(private afs: Firestore) {}

  getPartnerCompany(id: string) {
    const docRef = doc(this.afs, '/role/finance_manager/partner_company', id) as DocumentReference<PartnerCompany>;
    return this.mapSnapshotToDocument<PartnerCompany>(docRef);
  }

  getPartnerCreditNote(settlementId: string) {
    const docRef = doc(this.afs, '/role/finance_manager/credit_note', settlementId) as DocumentReference<CreditNote>;
    return this.mapSnapshotToDocument<CreditNote>(docRef);
  }

  listAreas() {
    return this.mapSnapshotsToDocuments<Area>(
      collection(this.afs, '/shared/current/area') as CollectionReference<Area>,
    );
  }

  listJBankSettlementFiles(): Observable<JBankSettlementFile[]> {
    return collectionData<JBankSettlementFile>(
      query<JBankSettlementFile, DocumentData>(
        collection(
          this.afs,
          '/role/finance_manager/j_bank_settlement_file',
        ) as CollectionReference<JBankSettlementFile>,
      ),
    );
  }

  listEconSettlementFiles(): Observable<EconSettlementFile[]> {
    return collectionData<EconSettlementFile>(
      query<EconSettlementFile, DocumentData>(
        collection(this.afs, '/role/finance_manager/econ_settlement_file') as CollectionReference<EconSettlementFile>,
      ),
    );
  }

  listPartnerCompanySettlements(partnerCompanyId: string): Observable<PartnerSettlement[]> {
    return collectionData<PartnerSettlement>(
      query<PartnerSettlement, DocumentData>(
        collection(
          this.afs,
          '/role/finance_manager/partner_company/' + partnerCompanyId + '/settlement',
        ) as CollectionReference<PartnerSettlement>,
      ),
    );
  }

  listDispatchTags() {
    const colRef = collection(this.afs, '/dispatch_tag') as CollectionReference<DispatchTag>;
    return this.mapSnapshotsToDocuments<DispatchTag>(colRef);
  }

  listVehicleTypes() {
    const colRef = collection(this.afs, '/vehicle_type') as CollectionReference<VehicleType>;
    return this.mapSnapshotsToDocuments<VehicleType>(colRef);
  }

  listProductFeatures(includeDeleted = false) {
    const colRef = collection(this.afs, '/feature') as CollectionReference<ProductFeature>;
    if (includeDeleted) {
      return this.mapSnapshotsToDocuments<ProductFeature>(colRef);
    } else {
      return this.mapSnapshotsToDocuments<ProductFeature>(colRef, [where('meta_op', '!=', MetaOp.DELETE)]);
    }
  }

  listExternalFees(includeDeleted = false) {
    const colRef = collection(this.afs, '/pricing_external_fee') as CollectionReference<ExternalFee>;
    if (includeDeleted) {
      return this.mapSnapshotsToDocuments<ExternalFee>(colRef);
    } else {
      return this.mapSnapshotsToDocuments<ExternalFee>(colRef, [where('meta_op', '!=', MetaOp.DELETE)]);
    }
  }

  watchAppConfig() {
    return this.watchCollection<AppConfig>(collection(this.afs, 'app_config'));
  }

  watchBooking(id: string) {
    return this.watchDocument<BookingAggregate>(doc(this.afs, '/booking_aggregate/', id));
  }

  watchRider(role: UserRoles, riderId: string) {
    return this.watchDocument<RiderAggregate>(doc(this.afs, `/role/${role}/rider_aggregate/`, riderId));
  }

  watchVehicle(role: UserRoles, id: string) {
    return this.watchDocument<VehicleAggregate>(doc(this.afs, `/role/${role}/vehicle_aggregate/`, id));
  }

  watchDriver(role: UserRoles, driverId: string) {
    return this.watchDocument<DriverAggregate>(doc(this.afs, `/role/${role}/driver_aggregate/`, driverId));
  }

  watchDriverUser(driverId: string) {
    return this.watchDocument<{ id: string; account_user: AccountUser }>(doc(this.afs, `/user_aggregate/`, driverId));
  }

  watchDriverState(role: UserRoles, driverId: string) {
    return this.watchDocument<{ id: string; state: DriverState }>(
      doc(this.afs, `/role/${role}/driver_aggregate/${driverId}/state/`, 'current'),
    );
  }

  watchDriverTelemetry(role: UserRoles, driverId: string) {
    return this.watchDocument<{ id: string; telemetry: DriverTelemetry }>(
      doc(this.afs, `/role/${role}/driver_aggregate/${driverId}/telemetry/`, 'current'),
    );
  }

  watchDriverEta(role: UserRoles, driverId: string, bookingId: string) {
    return this.watchDocument<DriverEta>(
      doc(this.afs, `/role/${role}/driver_aggregate/${driverId}/pickup_eta/${bookingId}'`),
    );
  }

  watchBusiness(role: UserRoles, businessId: string) {
    return this.watchDocument<Business>(doc(this.afs, `/role/${role}/business/`, businessId));
  }

  watchQuote(id: string) {
    return this.watchDocument<Quote>(doc(this.afs, '/quote/', id));
  }

  watchPartnerCompany(role: UserRoles, partnerId: string) {
    return this.watchDocument<PartnerCompanyAggregate>(
      doc(this.afs, `/role/${role}/partner_company_aggregate/`, partnerId),
    );
  }

  filterDriverUsers(driverIds: string[]) {
    return this.filterDocuments<AccountUser>(
      collection(this.afs, '/user_aggregate') as CollectionReference<AccountUser>,
      driverIds,
    );
  }

  // TODO - refactor after console firebase user migration to account users
  filterFirebaseUsers(ids: string[] = null) {
    const colRef = collection(this.afs, '/firebase_user') as CollectionReference<FirebaseUser>;
    if (ids === null) {
      return this.mapSnapshotsToDocuments<FirebaseUser>(colRef);
    }
    return this.filterDocuments<FirebaseUser>(colRef, ids);
  }

  filterRiders(role: UserRoles, ids: string[]) {
    const colRef = collection(this.afs, `/role/${role}/rider_aggregate`) as CollectionReference<RiderAggregate>;
    return this.filterDocuments<RiderAggregate>(colRef, ids);
  }

  filterDrivers(role: UserRoles, ids: string[] = null) {
    const colRef = collection(this.afs, `/role/${role}/driver_aggregate`) as CollectionReference<DriverAggregate>;

    if (ids === null) {
      return this.mapSnapshotsToDocuments<DriverAggregate>(colRef);
    }
    return this.filterDocuments<DriverAggregate>(colRef, ids);
  }

  filterBookings(ids: string[]) {
    return this.filterDocuments<BookingAggregate>(
      collection(this.afs, '/booking_aggregate') as CollectionReference<BookingAggregate>,
      ids,
    );
  }

  filterBusinesses(role: UserRoles, ids: string[] = null) {
    const colRef = collection(this.afs, `/role/${role}/business`) as CollectionReference<Business>;
    if (ids === null) {
      return this.mapSnapshotsToDocuments<Business>(colRef);
    }
    return this.filterDocuments<Business>(colRef, ids);
  }

  filterVehicles(role: UserRoles, ids: string[] = null) {
    return this.filterDocuments<VehicleAggregate>(
      collection(this.afs, `/role/${role}/vehicle_aggregate`) as CollectionReference<VehicleAggregate>,
      ids,
    );
  }

  private filterDocuments<T>(colRef: CollectionReference<T>, ids: string[]): Observable<T[]> {
    const idBatches = this.splitIntoBatches(ids);
    return from(idBatches).pipe(
      mergeMap(batch => {
        const q = query(colRef, where(documentId(), 'in', batch));
        return from(getDocs(q)).pipe(
          map(snapshot => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as T)),
          catchError(error => this.handleError(error, 'filterDocuments')),
        );
      }),
      toArray(),
      map(results => results.flat()),
    );
  }

  private splitIntoBatches<T>(array: T[], batchSize = 5): T[][] {
    const batches: T[][] = [];
    for (let i = 0; i < array.length; i += batchSize) {
      batches.push(array.slice(i, i + batchSize));
    }
    return batches;
  }

  private watchCollection<T>(colRef: CollectionReference<DocumentData>): Observable<T> {
    return new Observable(observer => {
      const q = query(colRef);

      const unsubscribe = onSnapshot(
        q,
        snapshot => {
          const docArr = snapshot.docs
            .filter(docSnap => docSnap.exists)
            .reduce((prev, curr) => ({ ...prev, [curr.id]: curr.data() }), {});
          observer.next(docArr as unknown as T);
        },
        error => {
          observer.error(error);
        },
      );
      return () => unsubscribe();
    });
  }

  private watchDocument<T>(docRef: DocumentReference<DocumentData>): Observable<T | null> {
    return new Observable(observer => {
      const unsubscribe = onSnapshot(
        docRef,
        docSnapshot => {
          if (docSnapshot.exists()) {
            observer.next({ id: docSnapshot.id, ...docSnapshot.data() } as unknown as T);
          } else {
            observer.next(null);
          }
        },
        error => {
          console.log('Error in watchDocument', error, docRef.path);
          observer.error(error);
        },
      );

      return () => unsubscribe();
    });
  }

  private mapSnapshotsToDocuments<T>(
    colRef: CollectionReference<T>,
    queryConstraints?: QueryConstraint[],
  ): Observable<T[]> {
    const documentQuery = queryConstraints ? query(colRef, ...queryConstraints) : query(colRef);
    return from(getDocs(documentQuery)).pipe(
      map(snapshot => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as T)),
      catchError(error => this.handleError(error, 'mapSnapshotsToDocuments')),
    );
  }

  private mapSnapshotToDocument<T>(docRef: DocumentReference<T>): Observable<T> {
    return from(getDoc(docRef)).pipe(
      map(snapshot => ({ id: snapshot.id, ...snapshot.data() }) as T),
      catchError(error => this.handleError(error, 'mapSnapshotToDocument')),
    );
  }

  private handleError(error: any, context: string): Observable<never> {
    console.error(`Error in ${context}:`, error);
    return throwError(() => new Error(error));
  }
}
